import { properties } from "./../assets/properties";

const validate = (inputs) => {
  const errors = {};
  Object.entries(inputs).forEach(([key, value], i) => {
    let fieldname = properties.FormFieldNames[key].fieldName;
    let maxLength = properties.FormFieldNames[key].maxLength;
    let isRequired = properties.FormFieldNames[key].required;
    let isMatchURLPattern = properties.FormFieldNames[key].matchURLPattern;
    let isMatchApplicationShortNamePattern = properties.FormFieldNames[key].matchAppShortNamePattern;

    if (!value && isRequired) {
      errors[key] = fieldname + " is required";
      document.getElementById(key).classList.add("input-error");
    }
    else if (value && isMatchURLPattern) {
      const input = value.toLowerCase();
      const re = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/;
      var is_url = re.test(input);
      if (!is_url) {
        errors[key] = "Invalid URL entered";
        document.getElementById(key).classList.add("input-error");
      }
    }
    else if (value && isMatchApplicationShortNamePattern) {
      const input = value.toLowerCase();
      const re = /^[A-Za-z0-9]+$/
      var is_applicationShortName = re.test(input);
      if (!is_applicationShortName) {
        errors[key] = properties.messageList.appShortNameErrMsg;
        document.getElementById(key).classList.add("input-error");
      }
    }
    else if (Array.isArray(value) && value.length === 0 && isRequired) {
      if (key === 'payments' && document.getElementById(key)) {
        errors[key] = properties.messageList.paymentMethodErrorMsg;
        document.getElementById(key).classList.add("input-error");
      }
    }
    else if (value.length > maxLength) {
      errors[key] = fieldname + " can not exceed " + maxLength + " characters";
      document.getElementById(key).classList.add("input-error");
    }
  });
  return errors;
}
export default validate;