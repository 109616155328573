import React, { useContext, useEffect, useState } from 'react';
import "../css/header.css";
import logo from './../assets/logo.png';
import headerList from './../assets/navbarmenu.json';
import { HeaderLink } from './HeaderLink';
import { HeaderLogo } from './HeaderLogo';
import { UserContext } from '../contexts/UserContext';
import { properties } from "./../assets/properties";
import { useMsal } from "@azure/msal-react";
import { signOutClickHandler } from './utils.js';
import { FiLogOut } from 'react-icons/fi';

function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance provided to MsalProvider
    const { instance } = useMsal();
    return <FiLogOut className="fa-icon" onClick={() => signOutClickHandler(instance)} />
    // return <button className="signout" onClick={() => signOutClickHandler(instance)}>Logout</button>
};

export function Header() {

    const [userDetails, setUserDetails] = useState({});
    const [resourceArr, setResourceArr] = useState([]);

    const userConsumerData = useContext(UserContext);

    const navbarLinkClass = {
        classVal: "navlink",
        activeClassVal: "activeNavLink"
    }

    function showList(){
        document.getElementById("caretdwn").style.display = "none";
        document.getElementById("caretup").style.display = "block";
        document.getElementById("userOptionsCont").style.display = "block";
    }
    function hideList(){
        document.getElementById("caretup").style.display = "none";
        document.getElementById("caretdwn").style.display = "block";
        document.getElementById("userOptionsCont").style.display = "none";
    }

    useEffect(() => {
        if (Object.keys(userConsumerData).length !== 0) {
            resourceArr.splice(0, resourceArr.length);
            userConsumerData.permissions.forEach(el => {
                resourceArr.push(el.resourceName);
            });
            setUserDetails(userConsumerData);
            setResourceArr(resourceArr);
        }
    }, [userConsumerData])

    return (
        <>
            <div className="header">
                <HeaderLogo logoURL={logo} classList="headerLogo" />
                <div className="navlinkContainer" id="navlinkContainer">
                    { Object.keys(userDetails).length !== 0 && headerList.map(navLink => resourceArr.indexOf(properties.resourceList[navLink.id]) > -1 && <HeaderLink key={navLink.id} url={navLink} classList={navbarLinkClass} /> )}
                </div>
                <div className="prof-nm">
                    <div className="userNameCircle">
                        <span className="userNameInitials">
                            {Object.keys(userDetails).length !== 0 && userDetails.firstName.charAt(0).toUpperCase() + userDetails.lastName.charAt(0).toUpperCase()}
                        </span>
                    </div>
                    <SignOutButton />
                    {/* <GoChevronDown className="fa-icon" id="caretdwn" onClick={showList}/>
                    <GoChevronUp className="fa-icon" id="caretup" onClick={hideList} style={{display: "none"}}/> */}
                </div>
                
            
            {/* <div className="userOptionsCont" id="userOptionsCont">
                    <ul className="dropdown-menu">
                        <li><SignOutButton /></li>
                    </ul>
                </div> */}
                </div>
        </>
    );
}