import React from 'react';
import {RiErrorWarningFill} from "react-icons/ri";

export function ErrorFound() {
  return (
    <div>
        <RiErrorWarningFill className="not-found-icon"/>
        <p className="not-found-para1">oops!</p>
        <p className="not-found-para2">Something went wrong. Please try again later.</p>
    </div>
  );
}