import { env } from "../env";

export function signOutClickHandler(instance) {
    // const homeAccountId = localStorage.loggedInUser;
    // const logoutRequest = {
    //     account: instance.getAccountByHomeId(homeAccountId),
    //     postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL
    // }
    // instance.logoutRedirect(logoutRequest);
    sessionStorage.clear();
    localStorage.clear();
    var logoutURL = env.REACT_APP_LOGOUT_URL+"?client_id="+env.REACT_APP_CLIENT_ID+"&post_logout_redirect_uri="+encodeURIComponent(env.REACT_APP_POST_LOGOUT_REDIRECT_URL);
    //var logoutURL = process.env.REACT_APP_LOGOUT_URL+"?post_logout_redirect_uri="+encodeURIComponent(process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL);
    window.location.href=logoutURL;
}