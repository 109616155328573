import React from 'react';
import { properties } from "../assets/properties";

export function ErrorFoundDialogbox() {
    
    function closeModal() {
        document.getElementById("errorModal").style.display = "none";
    }
    return (
        <>
            <div className="confirm-dialog-modal" id="errorModal">
                <div className="confirm-dialog-box">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="confirm-dialog-box-inner">
                        <h2>Oops!</h2>
                        <p>Something went wrong. Please try again later.</p>
                        <div className="btn-grp">
                            <button type="button" className="btn blue-btn" onClick={closeModal}>{properties.OK}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}