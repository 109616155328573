import React from 'react';
import LoaderIcon from "react-loader-spinner";

export function Loader(columns) {
  return (
    <tr className="loader center">
      <td colSpan={columns.columns}>
        <div>
          <LoaderIcon
            type="ThreeDots"
            color="#3A7CB3"
            height={60}
            width={60}
            //timeout={3000}
          />
        </div>
      </td>
    </tr>
  );
}