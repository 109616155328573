import { useEffect, useState } from "react"
import axios from 'axios';
import { properties } from "../assets/properties";
import validate from './validate';
import { useForm } from './customHooks';
import { PageNotFound } from './PageNotFound';
import { GenerateDropdownOptions } from "./GenerateDropdownOptions";
import { ResponseMsg } from './ResponseMsg';
import { ConfirmDialogbox } from "./ConfirmDialogbox";
import { PaymentMethodAccordion } from "./PaymentMethodAccordion";
import { env } from "../env";

export function ViewEditClientApplication(props) {

    const { appId } = props.match.params;
    const baseURL = env.REACT_APP_BASE_URL;
    const [clientAppData, setClientAppData] = useState({});
    const [showPageNotFound, setPageNotFound] = useState(false);
    const [fetchedResult, setFetchedResult] = useState(false);
    const [typeList, setTypeList] = useState({});
    const [businessUnitList, setBusinessUnitList] = useState({});
    const [payments, setPayments] = useState([]);
    const [statusOptionList, setStatusOptionList] = useState({});
    const [clientAdmins, setClientAdmins] = useState({});
    const [clientAppUpdateUrl, setClientAppUpdateUrl] = useState();
    const [promotionId, setPromotionId] = useState();
    let usedInputs = [];
    let initialValues = {};
    if(props.registerPermission) {
        usedInputs = ['type','businessUnit', 'name', 'appShortName', 'template', 'targetUrl', 'lansaUrl', 'userId', 'payments'];
        initialValues = { type: '',businessUnit: '', name: '', appShortName: '', template: '', targetUrl: '', lansaUrl: '', userId: '', payments: [] };
    }else if(props.addPermission && props.role === properties.dbRoleList.WPS_ADMIN){
        usedInputs = ['type', 'businessUnit', 'name', 'appShortName', 'secret', 'template', 'targetUrl', 'lansaUrl', 'userId', 'payments'];
        initialValues = { type: '', businessUnit: '', name: '', appShortName: '', secret: '', template: '', targetUrl: '', lansaUrl: '', userId: '', payments: [] };
    }else {
        usedInputs = ['type', 'businessUnit', 'name', 'appShortName', 'secret', 'template', 'targetUrl', 'lansaUrl', 'status', 'userId', 'payments'];
        initialValues = { type: '', businessUnit: '', name: '', appShortName: '', secret: '', template: '', targetUrl: '', lansaUrl: '', status: '', userId: '', payments: [] };
    }
    const cancelUrl = (props.approvalPermission) ? "goback" : (props.promotePermission ? "gotopromote" : "");
    const ClassNameList = (props.approvalPermission) ? " approve-client-app" : "";
    const restrictedStatList = props.restrictedStatList;

    const {
        inputs,
        errors,
        actionResp,
        confirmMsg,
        handleSubmit,
        handleInputChange,
        cancelRegister,
        updateData,
        openModal,
        isTargetMandatory,
        setIsTargetMandatory } = useForm(initialValues, validate, props.tokenId, clientAppUpdateUrl, cancelUrl);

    useEffect(() => {
        setTypeList(properties.typeList);
        setBusinessUnitList(properties.businessUnitList);
    }, [])

    const onChangePaymentMethod = (selectedPayments, e) => {
        setPayments(selectedPayments);
        handleInputChange({
            ...e, target: { ...e.target, id: e.target.id, value: selectedPayments, classList: e.target.classList, name: "payments" },
        })
    }
    const fetchClientApplicationDetails = async () => {
        const clientApplicationUrl = properties.apiList.clientApplicationUrl.replace("[clientAppId]", appId);
        try {
            const clientAppRes = await axios.get(baseURL + clientApplicationUrl,
                {
                    headers: {
                        "Authorization": 'Bearer ' + props.tokenId
                    }
                })
            setClientAppData(clientAppRes.data);
            setPromotionId(clientAppRes.data.action.clientPromotionId)
            if (clientAppRes.data.type === properties.typeList.SOAP) {
                setIsTargetMandatory(false)
                properties.FormFieldNames.targetUrl.required = false;
                properties.FormFieldNames.template.required = false;
            } else {
                setIsTargetMandatory(true)
                properties.FormFieldNames.targetUrl.required = true;
                properties.FormFieldNames.template.required = true;
            }
            Object.keys(clientAppRes.data).length > 0 && setPageNotFound(true);

            setClientAppUpdateUrl(properties.apiList.submitEditClientAppUrl.replace("{clientAppId}", clientAppRes.data.clientAppId))

            const filteredUsedInputs = Object.keys(clientAppRes.data)
                .filter(key => usedInputs.includes(key))
                .reduce((obj, key) => {
                    obj[key] = clientAppRes.data[key];
                    return obj;
                }, {});

            updateData(filteredUsedInputs);
        }
        catch (e) {
            setPageNotFound(true);
        }
    }

    const fetchStatusOptionData = async () => {
        try{
            let statusList = [];
            if (!props.editPermission && !props.addPermission && !props.registerPermission) {
                //disabled field
                if (props.approvalPermission){
                    statusList[clientAppData.status] = properties.statusList[clientAppData.status];
                }
                else {
                    statusList[clientAppData.status] = properties.filteredStatusList[clientAppData.status];
                }
            }
            else {
                //enabled field

                // const statusOptionRes = await axios.get(baseURL + properties.apiList.fetchStatus,
                //     {
                //         headers: {
                //             "Authorization": 'Bearer ' + props.tokenId
                //         }
                //     }
                // )
                const statusOptionRes = { data: properties.statusList };

                if(props.approvalPermission){
                    Object.keys(statusOptionRes.data).forEach(el => {
                        if (properties.statusList[el] !== undefined) {
                            statusList[el] = properties.statusList[el];
                        }
                    })
                }
                else{
                    Object.keys(statusOptionRes.data).forEach(el => {
                        if (properties.filteredStatusList[el] !== undefined) {
                            statusList[el] = properties.filteredStatusList[el];
                        }
                    })
                }
            }
            setStatusOptionList(statusList);
        }catch (e) {
        }
    }

    const fetchClientAdmins = async () => {
        try{
            let clientAdminArr = [];
            if (!props.editPermission && !props.addPermission && !props.registerPermission) {
                //disabled field
                clientAdminArr = [clientAppData.ssoId];
            }
            else {
                //enabled field
                const clientAdminRes = await axios.get(baseURL + properties.apiList.fetchClientAdmins.replace("{adminRole}", "Client_Admin"),
                    {
                        headers: {
                            "Authorization": 'Bearer ' + props.tokenId
                        }
                    }
                )
                clientAdminRes.data.forEach(el => {
                    clientAdminArr[el.userId] = el.ssoId;
                })
            }
            setClientAdmins(clientAdminArr);
        }catch (e) {
        }
    }

    const fetchSecretKey = async () => {
        try{
            const secretKey = await axios.get(baseURL + properties.apiList.getSecretKeyUrl,
                {
                    headers: {
                        "Authorization": 'Bearer ' + props.tokenId
                    }
                }
            )
            initialValues.secret = secretKey.data;
            initialValues.userId = "";
            updateData(initialValues);
        }catch (e) {
        }
    }

    useEffect(() => {
        if(!props.addPermission && !props.registerPermission) {
            fetchClientApplicationDetails();
            setFetchedResult(true);
        }
        else {
            setFetchedResult(true);
        }
        if(props.addPermission || props.registerPermission) {
            setClientAppUpdateUrl(properties.apiList.addRegisterNewClientApplicationUrl)
            properties.FormFieldNames.targetUrl.required = false;
        }
        if(props.addPermission) {
            fetchSecretKey();
        }
    }, []);

    useEffect(() => {
        if (fetchedResult) {
            fetchStatusOptionData();
            fetchClientAdmins();
        }
    }, [fetchedResult,clientAppData])

    let hasPermission = (Object.keys(clientAppData).length > 0 && (props.viewPermission || props.editPermission || (props.rollbackPermission && clientAppData.action.rollback) || props.approvalPermission || props.promotePermission));
    let addOrRegisterApp = (props.registerPermission || props.addPermission);
    return (
        <>
            <ResponseMsg msg={actionResp} />
            {confirmMsg !== "" && <ConfirmDialogbox confirmMsg={confirmMsg} appId={appId} promotionId={promotionId} appData={inputs} tokenId={props.tokenId} />}
            {(addOrRegisterApp || (hasPermission && restrictedStatList.includes(inputs["status"])) ) ?
                <div className={"reg-nw-user" + ClassNameList}>
                    <h1 className="margin-top-zero">
                        {props.editPermission ? properties.pageHeaders.editClientApplicationHeader :
                            props.approvalPermission ? properties.pageHeaders.approveDeclineClientApplicationHeader :
                                props.promotePermission ? properties.pageHeaders.promoteClientApplicationHeader :
                                    props.addPermission ? properties.pageHeaders.addNewClientApplication :
                                        props.registerPermission ? properties.pageHeaders.registerNewClientApplication :
                                            props.rollbackPermission ? properties.pageHeaders.rollbackClientApplication : properties.pageHeaders.viewClientApplicationHeader}
                    </h1>
                    <form method="post" className="reg-nw-user-form" onSubmit={(e) => handleSubmit(e, (props.addPermission || props.registerPermission) ? properties.methodList.POST : properties.methodList.PUT, props.editPermission ? properties.messageList.editClientAppSuccessMsg : props.addPermission ? properties.messageList.addNewClientApplicationMsg : properties.messageList.registerNewClientApplicationMsg, (props.addPermission || props.registerPermission) ? properties.TRUE : properties.FALSE)}>
                        <div className="row form-group">
                            <label htmlFor="type">{properties.FormFieldNames.type.fieldName}<span>*</span></label>
                            <select className="form-control" name="type" id="type" value={inputs["type"]} onChange={handleInputChange} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} >
                                <option value="" disabled={true}>{properties.SELECT}</option>
                                <GenerateDropdownOptions dataList={typeList} />
                            </select>
                            {errors.type && <span className="input-err-msg">{errors.type}</span>}
                        </div>
                        <div className="row form-group">
                            <label htmlFor="businessUnit">{properties.FormFieldNames.businessUnit.fieldName}<span>*</span></label>
                            <select className="form-control" name="businessUnit" id="businessUnit" value={inputs["businessUnit"]} onChange={handleInputChange} disabled={!props.editPermission && !props.addPermission && !props.registerPermission}>
                                <option value="" disabled={true}>{properties.SELECT}</option>
                                <GenerateDropdownOptions dataList={businessUnitList} />
                            </select>
                            {errors.businessUnit && <span className="input-err-msg">{errors.businessUnit}</span>}
                        </div>
                        <div className="row form-group">
                            <label htmlFor="name">{properties.FormFieldNames.name.fieldName}<span>*</span></label>
                            <input type="text" name="name" className="form-control" id="name" onChange={handleInputChange} placeholder="" value={inputs.name} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} />
                            {errors.name && <span className="input-err-msg">{errors.name}</span>}
                        </div>
                        <div className="row form-group">
                            <label htmlFor="appShortName">{properties.FormFieldNames.appShortName.fieldName}<span>*</span></label>
                            <input type="text" name="appShortName" className="form-control" id="appShortName" onChange={handleInputChange} placeholder="" value={inputs.appShortName} disabled={!addOrRegisterApp} />
                            {errors.appShortName && <span className="input-err-msg">{errors.appShortName}</span>}
                        </div>
                        {!props.registerPermission &&
                            <div className="row form-group">
                                <label htmlFor="secret">{properties.FormFieldNames.secret.fieldName}<span>*</span></label>
                                <input type="text" name="secret" className="form-control" id="secret" onChange={handleInputChange} placeholder="" disabled={true} value={inputs.secret} />
                                {errors.secret && <span className="input-err-msg">{errors.secret}</span>}
                            </div>
                        }
                        <div className="row form-group">
                            <label htmlFor="userId">{properties.FormFieldNames.userId.fieldName}<span>*</span></label>
                            <select className="form-control" name="userId" id="userId" value={inputs["userId"]} onChange={handleInputChange} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} >
                                <option value="" disabled={true}>{properties.SELECT}</option>
                                <GenerateDropdownOptions dataList={clientAdmins} />
                            </select>
                            {errors.userId && <span className="input-err-msg">{errors.userId}</span>}
                        </div>
                        {(!props.registerPermission && !(props.addPermission && props.role === properties.dbRoleList.WPS_ADMIN)) &&
                            <div className="row form-group">
                                <label htmlFor="status">{properties.FormFieldNames.status.fieldName}<span>*</span></label>
                                <select className="form-control" name="status" id="status" value={inputs["status"]} onChange={handleInputChange} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} >
                                    <option value="" disabled={true}>{properties.SELECT}</option>
                                    <GenerateDropdownOptions dataList={statusOptionList} />
                                </select>
                                {errors.status && <span className="input-err-msg">{errors.status}</span>}
                            </div>
                        }
                        <div className="row form-group">
                            <label htmlFor="template">{properties.FormFieldNames.template.fieldName}{isTargetMandatory && <span>*</span>}</label>
                            <textarea type="text" name="template" className="form-control textareaField" id="template" onChange={handleInputChange} placeholder="" value={inputs.template} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} ></textarea>
                            {(isTargetMandatory && errors.template) && <span className="input-err-msg">{errors.template}</span>}
                        </div>
                        <div className="row form-group">
                            <label htmlFor="targetUrl">{properties.FormFieldNames.targetUrl.fieldName}{isTargetMandatory && <span>*</span>}</label>
                            <input type="text" name="targetUrl" className="form-control" id="targetUrl" onChange={handleInputChange} placeholder="" value={inputs.targetUrl} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} />
                            {(isTargetMandatory && errors.targetUrl) && <span className="input-err-msg">{errors.targetUrl}</span>}
                        </div>
                        <div className="row form-group">
                            <label htmlFor="lansaUrl">{properties.FormFieldNames.lansaUrl.fieldName}</label>
                            <input type="text" name="lansaUrl" className="form-control" id="lansaUrl" onChange={handleInputChange} placeholder="" value={inputs.lansaUrl} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} />
                            {errors.lansaUrl && <span className="input-err-msg">{errors.lansaUrl}</span>}
                        </div>
                        <div className="row form-group">
                            <label htmlFor="payments" className="paymentMethodLabel">{properties.FormFieldNames.payments.fieldName}<span>*</span></label>
                            <PaymentMethodAccordion paymentMethodList={properties.paymentMethodList} payments={payments} disabled={!props.editPermission && !props.addPermission && !props.registerPermission} onChangePaymentMethod={onChangePaymentMethod} inputPaymentsData={inputs['payments'] || []} />
                            {errors.payments && <span className="input-err-msg">{errors.payments}</span>}
                        </div>
                        <div className="btn-grp">
                            {props.approvalPermission && <button type="button" className="btn white-btn" data-confirm={properties.DECLINE} onClick={openModal}>{properties.DECLINE}</button>}
                            {props.approvalPermission && <button type="button" className="btn blue-btn" data-confirm={properties.APPROVE} onClick={openModal}>{properties.APPROVE}</button>}
                            <button type="button" className="btn white-btn back-btn" onClick={cancelRegister}>{(props.editPermission || props.addPermission || props.registerPermission || props.rollbackPermission) ? properties.CANCEL : properties.BACK}</button>
                            {props.promotePermission && <button type="button" className="btn blue-btn" data-confirm={properties.PROMOTE} onClick={openModal}>{properties.PROMOTE}</button>}
                            {props.editPermission && <button type="submit" className="btn blue-btn">{properties.SAVE}</button>}
                            {props.addPermission && <button type="submit" className="btn blue-btn">{properties.ADD}</button>}
                            {props.registerPermission && <button type="submit" className="btn blue-btn">{properties.REGISTER}</button>}
                            {props.rollbackPermission && <button type="button" className="btn blue-btn" data-confirm={properties.REVERT} onClick={openModal}>{properties.CONFIRM}</button>}
                        </div>
                    </form>
                </div> :
                showPageNotFound && <PageNotFound />
            }
        </>
    )
}