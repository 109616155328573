import { LogLevel } from "@azure/msal-browser";
import { env } from "../env";

export const msalConfig = {
    auth: {
        clientId: env.REACT_APP_CLIENT_ID,
        authority: env.REACT_APP_AUTHORITY,
        redirectUri: env.REACT_APP_REDIRECT_URL
    },
    cache: {
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;	
                    default:
                        return;    	
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: [env.REACT_APP_SCOPE]
};

