export function GenerateDropdownOptions({ dataList }) {
    /*
    dataList is an object (JSON Array) that contains all options
    */

    return (
        <>
            {Object.entries(dataList).map(([key, value], i) => (<option key={i} value={key}>
                {value}
            </option>))}
        </>
    )

}