export const properties = {
    footerContent: "<a href='https://www.wiley.com/en-us/copyright' target='_blank'>Copyright &copy; 2000-[year]</a>&nbsp;by John Wiley & Sons, Inc., or related companies. All rights reserved.",
    pageNotFoundContent: "<h1>404</h1><h2>Oops, page not found!</h2><p>Either something went wrong or the page doesn’t exist.</p>",
    statusList: { ACTIVE: "Active", INACTIVE: "Inactive", SUSPENDED: "Suspended", AWAITING_ACTIVATION: "Awaiting", DECLINED: "Declined" },
    filteredStatusList: { ACTIVE: "Active", INACTIVE: "Inactive", SUSPENDED: "Suspended" },
    editAppStatusList: ["ACTIVE", "INACTIVE", "SUSPENDED"],
    approveAppStatusList: ["AWAITING_ACTIVATION"],
    promoteProdStatusList: ["ACTIVE"],
    resourceList: {
        HOME_SCREEN: "home_screen",
        HOME_APP_VIEW_ICON: "home_client_app_view_icon",
        HOME_APP_VIEW_SCREEN: "home_client_app_view_screen",
        HOME_APP_EDIT_ICON: "home_client_app_edit_icon",
        HOME_APP_EDIT_SCREEN: "home_client_app_edit_screen",
        HOME_TRANSACTION_ICON: "home_transaction_icon",
        HOME_ROLLBACK_ICON: "home_rollback_icon",
        HOME_ROLLBACK_SCREEN: "home_rollback_screen",
        NEW_CLIENT_APP_SCREEN: "new_client_app_screen",
        REGISTER_NEW_USER_SCREEN: "register_new_user_screen",
        NEW_CLIENT_APP_ADD: "new_client_app_add",
        NEW_CLIENT_APP_REGISTER: "new_client_app_register",
        MY_WORKLIST_SCREEN: "my_worklist_screen",
        MY_WORKLIST_ICON: "my_worklist_icon",
        MY_WORKLIST_CONFIRMATION_SCREEN: "my_worklist_confirmation_screen",
        PROMOTE_TO_PROD_ICON: "promote_to_prod_icon",
        PROMOTE_TO_PROD_SCREEN: "promote_to_prod_screen",
        PROMOTE_TO_PROD_CONFIRMATION_SCREEN: "promote_to_prod_confirmation_screen",
        TRANSACTION_SEARCH_SCREEN: "transaction_search_screen"
    },
    permissionList: { VIEW: "view", ADD: "add", MODIFY: "modify", DELETE: "delete" },
    tableDefaultRecords: 10,
    dbRoleList: { WPS_ADMIN: "wps_admin", WPS_SUPPORT: "wps_support", CLIENT_ADMIN: "client_admin", APPLICATION_USER: "application_user", SERVICEDESK_USER: "servicedesk_user" },
    roleList: { WPS_Admin: "WPS Admin", WPS_Support: "WPS Support", Client_Admin: "Client Admin", Application_User: "Application User", Servicedesk_User: "Service Desk User" },
    urlList: {
        viewClientApplication: "/view-client-application/:appId",
        editClientApplication: "/edit-client-application/:appId",
        rollbackClientApplication: "/revert-client-application/:appId",
        approveDeclineClientApplication: "/approve-decline-client-application/:appId",
        promoteClientApplication: "/promote-client-application/:appId"
    },
    apiList: {
        registerUserURL: "/v1/users",
        getClientAppByParamsURL: "/v1/clients/search?{searchParams}",
        fetchPromoteAppURL: "/v1/clients/promotions",
        clientApplicationUrl: "/v1/clients/[clientAppId]",
        fetchClientAdmins: "/v1/users/search?role={adminRole}",
        fetchStatus: "/v1/clients/statuses",
        submitEditClientAppUrl: "/v1/clients/{clientAppId}",
        approveDeclineClientAppURL: "/v1/clients/{clientId}",
        promoteClientAppURL: "/v1/clients/{clientId}/promote",
        addRegisterNewClientApplicationUrl: "/v1/clients",
        getSecretKeyUrl: "/v1/clients/secret",
        rollbackClientApplicationUrl: "/v1/clients/{clientPromotionId}/rollback"
    },
    // typeList: { HTTP: "HTTP", SOAP: "SOAP", ANY: "ANY" },
    typeList: { HTTP: "HTTP", SOAP: "SOAP" },
    pageHeaders: {
        RegNewUserHeader: "Register New User",
        editClientApplicationHeader: "Edit Client Application",
        viewClientApplicationHeader: "View Client Application",
        approveDeclineClientApplicationHeader: "Approve Client Application",
        promoteClientApplicationHeader: "Promote Client Application",
        worklistTabHeader: "Worklist",
        promoteToProdTabHeader: "Promote to Production",
        addNewClientApplication: "Add New Client Application",
        registerNewClientApplication: "Register New Client Application",
        rollbackClientApplication: "Revert Client Application"
    },
    FormFieldNames: {
        type: { fieldName: "Type", maxLength: 35, required: true, name: "type" },
        businessUnit: { fieldName: "Business Unit", maxLength: 45, required: true, name: "businessUnit" },
        firstName: { fieldName: "First Name", maxLength: 35, required: true, name: "firstName" },
        lastName: { fieldName: "Last Name", maxLength: 35, required: true, name: "lastName" },
        ssoId: { fieldName: "User's SSO ID", maxLength: 35, required: true, name: "ssoId" },
        role: { fieldName: "Role", maxLength: 35, required: true, name: "role" },
        name: { fieldName: "Name", maxLength: 35, required: true, name: "name" },
        appShortName: { fieldName: "Application Short Name", maxLength: 50, required: true, name: "appShortName", matchAppShortNamePattern: true },
        secret: { fieldName: "Secret", maxLength: 35, required: true, name: "secret" },
        template: { fieldName: "Template", maxLength: Infinity, required: false, name: "template" },
        targetUrl: { fieldName: "Target URL", maxLength: Infinity, required: false, name: "targetUrl", matchURLPattern: true },
        lansaUrl: { fieldName: "LANSA URL", maxLength: 255, required: false, name: "lansaUrl" },
        status: { fieldName: "Status", maxLength: 35, required: true, name: "status" },
        userId: { fieldName: "User ID", maxLength: 35, required: true, name: "userId" },
        payments: { fieldName: "Payment Method", maxLength: 45, required: true, name: "payments" },
    },
    messageList: {
        userRegisterSuccessMsg: "User has been registered successfully",
        editClientAppSuccessMsg: "Your changes have been saved successfully",
        approveClientAppSuccessMsg: "{clientAppName} application has been approved",
        declineClientAppSuccessMsg: "{clientAppName} application has been declined",
        promoteClientAppSuccessMsg: "Production request for {clientAppName} application has been initiated",
        addNewClientApplicationMsg: "{clientAppName} application has been added successfully",
        registerNewClientApplicationMsg: "{clientAppName} application has been submitted for registration. Approval pending.",
        revertClientAppSuccessMsg: "Revert request for {clientAppName} application has been initiated",
        logoutMsg: "You have been logged out from WPS Client Portal. Please login to continue.",
        paymentMethodErrorMsg: "Please select at least one payment method",
        appShortNameErrMsg: "Special character/whitespace is not allowed for application shortname. Please provide another value."
    },
    methodList: {
        POST: "post",
        PUT: "put"
    },
    TRUE: true,
    FALSE: false,
    SAVE: "Save",
    CANCEL: "Cancel",
    BACK: "Back",
    SELECT: "Select",
    APPROVE: "approve",
    DECLINE: "decline",
    CONFIRM: "Confirm",
    ADD: "Add",
    REGISTER: "Register",
    REVERT: "revert",
    PROMOTE: "promote",
    OK: "Ok",
    LOGIN: "Login",
    tableHiddenColumns: ["pending_from"],
    myWorklistTableHiddenColumns: ["status", "action"],
    businessUnitList: { APL: "APL", Research: "Research", WES: "WES" },
    paymentMethodList: [
        { id: "Wallet", title: "Wallet", options: [{ id: "Alipay", title: "Alipay" }] }
    ]
};