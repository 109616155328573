import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useFilters } from "react-table";
import { TablePagination } from './TablePagination';
import { Loader } from "./Loader";
import { NoDataFound } from "./NoDataFound";
import {ErrorFound} from "./ErrorFound";

export default function CustomTable(props) {

    let columns = props.columns, data = props.data;
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    let totColumns = parseInt(props.columns.length - props.hiddenColumns.length);
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, hiddenColumns: props.hiddenColumns }
    }, useFilters, usePagination);

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

    useEffect(() => {
        setLoading(props.loading);
    },[props.loading]);
    
    useEffect(() => {
        setShowError(props.showError);
    },[props.showError]);
    return (
        <>
            <table {...getTableProps()} className={props.classValList} >
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()} >
                                            {column.render('Header')}
                                            <div>
                                                {column.canFilter ? column.render('Filter') : null}
                                            </div>
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()} >
                    {!loading ? (
                        page.length > 0 ? (
                        page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} >
                                    {
                                        row.cells.map(cell => (
                                            <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                        ))
                                    }
                                </tr>
                            )
                        })
                        ) : (
                        showError ?
                            <tr className="center no-data-found">
                                <td colSpan={totColumns}>
                                    <ErrorFound />
                                    </td>
                            </tr>    
                        :    
                        <NoDataFound columns={totColumns} />
                        )) : (
                            <Loader columns={totColumns}/>
                    )}
                </tbody>
            </table>
            {page.length > 0 && <TablePagination instance={tableInstance} data={data} />}
        </>
    )
}