import React from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { properties } from "../assets/properties";
import LoaderIcon from "react-loader-spinner";

export function LoginRedirect() {

   const isAuthenticated = useIsAuthenticated();
   const { instance } = useMsal();
   const queryString = window.location.search;
   const urlParams = new URLSearchParams(queryString);
   if (isAuthenticated === false && !urlParams.has('redirect_to_login')) {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
   }
   function redirectLogin(){
        window.history.replaceState(null, null, window.location.pathname);
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
   }

    return(
    <>
    {urlParams.has('redirect_to_login') ?
        <div className="confirm-dialog-modal" id="loginModal" style={{display:'block'}}>
            <div className="confirm-dialog-box">
                <div className="confirm-dialog-box-inner">
                    <p>{properties.messageList.logoutMsg}</p>
                    <div className="btn-grp">
                        <button type="button" className="btn blue-btn" onClick={redirectLogin}>{properties.LOGIN}</button>
                    </div>
                </div>
            </div>
        </div>
    :
    <div className="blank-loader">
        <LoaderIcon type="Oval" color="#3A7CB3" height={60} width={60} />
    </div>
    }
    </>
    );
}