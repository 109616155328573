import React, { useEffect, useState } from 'react';

export const PaymentMethodAccordion = ({ payments, paymentMethodList, disabled, onChangePaymentMethod, inputPaymentsData }) => {
    const [togglePaymentMethodList, setTogglePaymentMethodList] = useState([]);

    useEffect(() => {
        let payments = [];
        let inputPaymentsDataVar = inputPaymentsData;
        if(inputPaymentsDataVar.length == 0 && paymentMethodList.length == 1){
            payments = [...payments, true];
        }
        for (var i = 0; i < paymentMethodList.length; i++) {
            const found = paymentMethodList[i].options.some(r => inputPaymentsDataVar.indexOf(r.title) >= 0);
            payments = [...payments, found];
        }
        setTogglePaymentMethodList(payments);
    }, [])

    const onClickToggleAccordion = (index) => {
        let paymentsToggleArr = togglePaymentMethodList;
        paymentsToggleArr[index] = !paymentsToggleArr[index];
        setTogglePaymentMethodList(prevArr => [...prevArr, paymentsToggleArr[index]]);
    }
    const handlePaymentsChange = (e) => {
        const { value } = e.currentTarget;
        if (e.target.checked) {
            inputPaymentsData = inputPaymentsData.includes(value) ? inputPaymentsData : [...inputPaymentsData, value];
        }
        else {
            inputPaymentsData = inputPaymentsData.includes(value) ? inputPaymentsData.filter(val => val !== value) : [...inputPaymentsData, value];
        }
        onChangePaymentMethod(inputPaymentsData, e);
    };

    return (
        <div className="accordion form-control" id="payments">
            {paymentMethodList.map(({ title, options, id }, index) => (
                <div className="accordion-item" key={id}>
                    <div className={`accordion-title ${disabled ? "disabled" : ""}`} onClick={disabled ? undefined : () => onClickToggleAccordion(index)}>
                        <span className={`accordionIcon ${togglePaymentMethodList[index] ? "accordionMinus" : "accordionPlus"}`}></span>
                        <span className="accordion-label">{title}</span>
                    </div>
                    <div className={`accordion-content ${togglePaymentMethodList[index] ? "open" : ""}`}>{options.map(({ title, id }) => (
                        <label htmlFor={id} className={`checkboxContainer ${disabled ? "disabled" : ""}`} key={id}>{title}
                            <input type="checkbox" name={id} id={id} value={title} className="accordionCheckBox"
                                defaultChecked={inputPaymentsData.includes(title)} disabled={disabled} onChange={(e) => {
                                    e.persist = () => { }
                                    handlePaymentsChange(e);
                                }} />
                            <span className="checkmark"></span>
                        </label>
                    ))
                    }
                    </div>
                </div>
            ))
            }
        </div>
    );
};
