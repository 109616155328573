import React from 'react';
import {RiErrorWarningFill} from "react-icons/ri";

export function NoDataFound(columns) {
  return (
    <tr className="center no-data-found">
      <td colSpan={columns.columns}>
        <div>
          <RiErrorWarningFill className="not-found-icon"/>
          <p className="not-found-para1">No records found!</p>
          <p className="not-found-para2">There are no records for you to see yet.</p>
        </div>
      </td>
    </tr>
  );
}