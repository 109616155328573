import React, { useState, useEffect } from 'react';
import { properties } from '../assets/properties';
import { BiLastPage, BiFirstPage, BiChevronLeft, BiChevronRight } from 'react-icons/bi';

const defaultRecordCount = properties.tableDefaultRecords;

function getTableRowNum(totalData) {
    let tableRowNum = Math.floor((window.innerHeight - 350) / 77);
    tableRowNum = (tableRowNum < defaultRecordCount && totalData > defaultRecordCount) ? defaultRecordCount :
        (tableRowNum > defaultRecordCount && totalData > tableRowNum) ? tableRowNum :
        totalData <= defaultRecordCount ? totalData : 1;
    return tableRowNum;
}

export function TablePagination({ instance, data }) {

    const {
        page, nextPage, previousPage, canNextPage, canPreviousPage, rows,
        pageOptions, gotoPage, pageCount, setPageSize, state
    } = instance;
    const { pageIndex, pageSize } = state;

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    }

    const [rowPage, setRowMinMax] = useState([1, pageSize]);

    const handleResize = () => {
        const tableRowCount = getTableRowNum(rows.length);
        setPageSize(tableRowCount);
    }

    useEffect(() => {
        let rowMin = rowPage[0], rowMax = rowPage[1];
        if (page !== undefined && page.length > 0) {
            if (rowMin === 1 && rows.length <= pageSize) {
                rowMax = rows.length;
            } else {
                rowMin = (pageSize * pageIndex) + 1;
                if(rows.length > pageSize * (pageIndex + 1)) {
                    rowMax = pageSize * (pageIndex + 1);
                }else {
                    rowMax = rows.length;
                }
            }
            setRowMinMax([rowMin, rowMax]);
        }
    }, [page]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <div className="nctable-paginate-info">
                <div className="nctable-info">Showing{' '}{rowPage[0]}{' '}to{' '}{rowPage[1]}{' '}of{' '}{rows.length}{' '}results</div>
                <div className="nctable-paginate">
                    <div className="prevbutton">
                        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}><BiFirstPage className="fa-icon" /></button>
                        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage} ><BiChevronLeft className="fa-icon" /></button>
                    </div>
                    <div className="pageNum">
                        <input type="text" value={pageIndex + 1} onChange={onChangeInInput} />
                    </div>
                    <div className="nxtbutton">
                        <button type="button" onClick={() => nextPage()} disabled={!canNextPage} ><BiChevronRight className="fa-icon" /></button>
                        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><BiLastPage className="fa-icon" /></button>
                    </div>
                </div>
            </div>
        </>
    )
}