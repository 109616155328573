import React from 'react';
import { properties } from "../assets/properties";

export function LoginRedirectDialogBox() {
    
    function redirectToLogin() {
        window.location.href = window.location.pathname;
    }
    return (
        <>
            <div className="confirm-dialog-modal" id="loginModal">
                <div className="confirm-dialog-box">
                    <div className="confirm-dialog-box-inner">
                        <p>{properties.messageList.logoutMsg}</p>
                        <div className="btn-grp">
                            <button type="button" className="btn blue-btn" onClick={redirectToLogin}>{properties.LOGIN}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}