import React from 'react';
import '../css/footer.css';
import { properties } from "./../assets/properties";
import parse from 'html-react-parser';

export function Footer() {
    let footerContent = properties.footerContent;
    let theDate=new Date();
    let toDate = theDate.getFullYear();
    footerContent = parse(footerContent.replace("[year]",toDate));
    return(
        <>
            <div className="footer">
                { footerContent }
            </div>
        </>
    );
}