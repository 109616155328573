import React, {useState, useEffect} from 'react';
import {IoClose} from "react-icons/io5";
export function ResponseMsg(props) {
    const [classNameList, setClass] = useState("");
    const [respMsg, setMsg] = useState({});

    useEffect(() => {
        if (props.msg !== undefined && props.msg !== null && props.msg.msg) {
          setMsg(props.msg);
          setClass((props.msg.success) ? "success-msg" : "err-msg");
          document.getElementById('resp-msg').scrollIntoView();
        }
        else{
            closeMsg();
        }
      }, [props.msg]);
        
    function closeMsg(){
        setMsg({});
        setClass("");
    }
    return(
        <div className={"response-msg-div "+classNameList } id="resp-msg">
            {respMsg !== undefined && respMsg !== null && respMsg.msg && <><span className="resp-msg resp">{respMsg.msg}</span><IoClose className="icon-close" onClick={closeMsg}/></>}
        </div>
    )
}