import React from 'react';
import { Redirect } from 'react-router-dom';
import { properties } from '../assets/properties';
import parse from 'html-react-parser';

export function PageNotFound(props) {
    let canRedirectToHome = true;
    if(props.location !== undefined && props.location.pathname === "/"){
        canRedirectToHome = false;
    }
    // if(document.getElementById("navlinkContainer").length){
    //     let redirectUrl = document.getElementById("navlinkContainer").firstChild.getAttribute("href");
    // }
    return (
        <>
        {canRedirectToHome ? 
        <div className="page-not-found">
            <div className="pnf-inner">
                {parse(properties.pageNotFoundContent)}
            </div>
        </div> :
        <Redirect to="/register-new-user" />
        }
        </>
    )
}