import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { HomeContent } from './HomeContent';
import { RegisterNewUser } from './RegisterNewUser';
import { MyWorkList } from './MyWorkList';
import { PageNotFound } from './PageNotFound';
import "../css/bodycontainer.css";
import { AppTokenConsumer } from './../contexts/AppTokenContext';
import { UserContext } from '../contexts/UserContext';
import { properties } from '../assets/properties';
import headerList from './../assets/navbarmenu.json';
import { ViewEditClientApplication } from './ViewEditClientApplication';

export function BodyContainer() {

    const [userDetails, setUserDetails] = useState({});
    const [resourcePermissionObj, setResourcePermissionObj] = useState({});
    const [canRedirectToHome, setRedirectHome] = useState(false);
    const [myWorklistProps, setMyWorklistProps] = useState({});
    let hiddenColumns = properties.tableHiddenColumns; 
    const [regUserRoleList, setRegUserRoleList] = useState({});

    const userConsumerData = useContext(UserContext);

    useEffect(() => {
        if (Object.keys(userConsumerData).length !== 0) {
            let resourcePermObj = {}, mwlp = {};
            mwlp.myWorklistHiddenColumns = properties.myWorklistTableHiddenColumns;
            userConsumerData.permissions.forEach((el, i) => {
                if (el.permissionNames.indexOf(properties.permissionList.MODIFY) > -1) {
                    resourcePermObj[el.resourceName] = properties.permissionList.MODIFY;
                    if(el.resourceName === properties.resourceList.MY_WORKLIST_ICON){
                        mwlp.myWorklistHiddenColumns.push("pending_from");
                        mwlp.myWorklistHiddenColumns = mwlp.myWorklistHiddenColumns.filter(e => e !== "action");
                    }
                } else if (el.permissionNames.indexOf(properties.permissionList.ADD) > -1) {
                    resourcePermObj[el.resourceName] = properties.permissionList.ADD;
                } else if (el.permissionNames.indexOf(properties.permissionList.DELETE) > -1) {
                    resourcePermObj[el.resourceName] = properties.permissionList.DELETE;
                } else if (el.permissionNames.indexOf(properties.permissionList.VIEW) > -1) {
                    resourcePermObj[el.resourceName] = properties.permissionList.VIEW;
                    if (el.resourceName === properties.resourceList.HOME_SCREEN) {
                        setRedirectHome(true);
                    }
                    if (el.resourceName === properties.resourceList.PROMOTE_TO_PROD_SCREEN) {
                        mwlp.showPromotoToProdTab = true;
                    }
                }

            });
            setUserDetails(userConsumerData);
            let appStorage = window.localStorage;
            appStorage.setItem('loggedInUser',userConsumerData.ssoId);
            setResourcePermissionObj(resourcePermObj);
            setMyWorklistProps(mwlp);
        }
    }, [userConsumerData]);

    useEffect(() => {
        let roleList = properties.roleList;
        if (Object.keys(userDetails).length !== 0) {
            if(userDetails.role === properties.dbRoleList.WPS_SUPPORT){
                delete roleList["WPS_Admin"];
            }
            if(userDetails.role === properties.dbRoleList.SERVICEDESK_USER){
                delete roleList["WPS_Admin"];
                delete roleList["WPS_Support"];
            }
            setRegUserRoleList(roleList);
        }
    }, [userDetails])

    return (
        <div className="bodyContainer">
            <AppTokenConsumer>
                {
                    (tokenId) => {
                        return (
                            <Switch>
                                {
                                    headerList.map(navLink => {
                                        if (properties.resourceList[navLink.id] in resourcePermissionObj && navLink.id !== "TRANSACTION_SEARCH_SCREEN") {
                                            return (
                                                <Route key={navLink.id} exact path={navLink.redirectURL} render={(props) => {
                                                    return properties.resourceList[navLink.id] === properties.resourceList.HOME_SCREEN ? <HomeContent {...props} tokenId={tokenId} role={userDetails.role} ssoId={userDetails.ssoId} hiddenColumns={hiddenColumns} /> :
                                                        properties.resourceList[navLink.id] === properties.resourceList.NEW_CLIENT_APP_SCREEN ? <ViewEditClientApplication {...props} addPermission={resourcePermissionObj[properties.resourceList.NEW_CLIENT_APP_ADD] !== undefined} registerPermission={resourcePermissionObj[properties.resourceList.NEW_CLIENT_APP_REGISTER] !== undefined} tokenId={tokenId} role={userDetails.role} /> :
                                                            properties.resourceList[navLink.id] === properties.resourceList.REGISTER_NEW_USER_SCREEN ? <RegisterNewUser {...props} tokenId={tokenId} canRedirectToHome={canRedirectToHome} regUserRoles={regUserRoleList} /> :
                                                                properties.resourceList[navLink.id] === properties.resourceList.MY_WORKLIST_SCREEN && <MyWorkList {...props} tokenId={tokenId} myWorklistProps={myWorklistProps} />
                                                }
                                                } />
                                            )
                                        }
                                        else return null;
                                    })
                                }
                                {properties.resourceList.HOME_APP_VIEW_ICON in resourcePermissionObj && <Route exact path={properties.urlList.viewClientApplication} render={(props) => <ViewEditClientApplication {...props} editPermission={properties.FALSE} viewPermission={properties.TRUE} tokenId={tokenId} restrictedStatList={properties.editAppStatusList} />}></Route>}
                                {properties.resourceList.HOME_APP_EDIT_ICON in resourcePermissionObj && <Route exact path={properties.urlList.editClientApplication} render={(props) => <ViewEditClientApplication {...props} editPermission={properties.TRUE} tokenId={tokenId} restrictedStatList={properties.editAppStatusList} />}></Route>}
                                {properties.resourceList.MY_WORKLIST_ICON in resourcePermissionObj && <Route exact path={properties.urlList.approveDeclineClientApplication} render={(props) => <ViewEditClientApplication {...props} approvalPermission={properties.TRUE} tokenId={tokenId} restrictedStatList={properties.approveAppStatusList}/>}></Route>}
                                {properties.resourceList.PROMOTE_TO_PROD_ICON in resourcePermissionObj && <Route exact path={properties.urlList.promoteClientApplication} render={(props) => <ViewEditClientApplication {...props} promotePermission={properties.TRUE} tokenId={tokenId} restrictedStatList={properties.promoteProdStatusList}/>}></Route>}
                                {properties.resourceList.HOME_ROLLBACK_ICON in resourcePermissionObj && <Route exact path={properties.urlList.rollbackClientApplication} render={(props) => <ViewEditClientApplication {...props} rollbackPermission={properties.TRUE} tokenId={tokenId} restrictedStatList={properties.editAppStatusList} />}></Route>}
                                {(resourcePermissionObj && Object.keys(resourcePermissionObj).length !== 0 && resourcePermissionObj.constructor === Object) && <Route exact component={PageNotFound}></Route>}
                            </Switch>
                        )
                    }
                }
            </AppTokenConsumer>
        </div>
    );
}