import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { properties } from '../assets/properties';
import { UserContext } from '../contexts/UserContext';
import { env } from "../env";

export function HeaderLink({ url, classList }) {
    const [userDetails, setUserDetails] = useState({});
    const userConsumerData = useContext(UserContext);
    let { pathname } = useLocation();

    useEffect(() => {
        if (Object.keys(userConsumerData).length !== 0) {
            setUserDetails(userConsumerData);
        }
    }, [userConsumerData]);

    if(Array.isArray(url.activeLink) && url.activeLink.length > 1){
        let urlList = url.activeLink, pn = pathname.split("/"), appId = "";
        if(pn.length > 2){
            appId = pn[2];
        }
        urlList.forEach((reUrl,index) =>
            {pathname = (appId !== "") ? pathname.replace(appId,':appId') : pathname; }
        )                                   
        return <NavLink className={ classList.classVal } activeClassName={ classList.activeClassVal } isActive={() => urlList.includes(pathname)} to={ url.redirectURL } >{ url.linkText }</NavLink>;
    }
    else if(url.external){
        var redirectURL = url.redirectURL;
        if(url.id === "TRANSACTION_SEARCH_SCREEN"){
            // redirectURL = process.env.REACT_APP_TRANSACTION_SEARCH_URL;
             if (userDetails.role !== undefined) {
                if (userDetails.role === properties.dbRoleList.APPLICATION_USER) {
                    redirectURL = env.REACT_APP_TRANSACTION_SEARCH_URL1;
                }
                else {
                    redirectURL = env.REACT_APP_TRANSACTION_SEARCH_URL2;
                }
            }
        }
        return <NavLink className={ classList.classVal } activeClassName={ classList.activeClassVal } to={{ pathname: redirectURL }} target="_blank">{ url.linkText }</NavLink>
    }
    else{
        return <NavLink exact className={ classList.classVal } activeClassName={ classList.activeClassVal } to={ url.redirectURL } >{ url.linkText }</NavLink>;
    }
}