import React from 'react';
import {useForm} from './customHooks';
import validate from './validate';
import { ResponseMsg } from './ResponseMsg';
import { GenerateDropdownOptions } from "./GenerateDropdownOptions";
import { properties } from "./../assets/properties";

export function RegisterNewUser(props) {
    const cancelRegisterUrl = "";
    const {inputs, errors, actionResp, handleSubmit, handleInputChange, cancelRegister} = useForm({firstName:'',lastName:'',ssoId:'',role:''},validate,props.tokenId,properties.apiList.registerUserURL,cancelRegisterUrl);
    return(
        <>
        <ResponseMsg msg={actionResp}/>
        <div className="reg-nw-user">
            <h1 className="margin-top-zero">{properties.pageHeaders.RegNewUserHeader}</h1>
            <form method="post" className="reg-nw-user-form" onSubmit={(e) => handleSubmit(e, properties.methodList.POST, properties.messageList.userRegisterSuccessMsg, properties.FALSE)}>
                <div className="row form-group form-group-usr-name grid-container">
                    <div className="form-grp-2 grid-child">
                        <label htmlFor="firstName">{properties.FormFieldNames["firstName"].fieldName}<span>*</span></label>
                        <input type="text" name="firstName" className="form-control" id="firstName" onChange={handleInputChange} placeholder="" value={inputs.firstName} />
                        {errors.firstName && <span className="input-err-msg">{errors.firstName}</span>}
                    </div>
                    <div className="form-grp-2 grid-child">
                        <label htmlFor="lastName">{properties.FormFieldNames["lastName"].fieldName}<span>*</span></label>
                        <input type="text" name="lastName" className="form-control" id="lastName" onChange={handleInputChange} placeholder="" value={inputs.lastName} />
                        {errors.lastName && <span className="input-err-msg">{errors.lastName}</span>}
                    </div>
                </div>
                <div className="row form-group">
                    <label htmlFor="ssoId">{properties.FormFieldNames["ssoId"].fieldName}<span>*</span></label>
                    <input type="text" name="ssoId" className="form-control" id="ssoId" onChange={handleInputChange} placeholder="" value={inputs.ssoId} />
                    {errors.ssoId && <span className="input-err-msg">{errors.ssoId}</span>}
                </div>
                <div className="row form-group">
                    <label htmlFor="ssoId">{properties.FormFieldNames["role"].fieldName}<span>*</span></label>
                    <select className="form-control" name="role" id="role" value={inputs["role"]} onChange={handleInputChange}>
                        <option value="" disabled>Select</option>
                        <GenerateDropdownOptions dataList={props.regUserRoles} />
                    </select>
                    {errors.role && <span className="input-err-msg">{errors.role}</span>}
                </div>
                <div className="btn-grp">
                    {props.canRedirectToHome && <button type="button" className="btn white-btn" onClick={cancelRegister}>Cancel</button>}
                    <button type="submit" className="btn blue-btn">Register</button>
                </div>
            </form>
        </div>
        </>
    );
}