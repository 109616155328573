import { HeaderLink } from './HeaderLink';
import { properties } from "./../assets/properties";
import { FaPen, FaEye } from 'react-icons/fa';
import { AiFillInfoCircle, AiFillCopy } from 'react-icons/ai';
import { ColumnFilter, SelectColumnFilter } from "./ColumnFilter";
import { UserConsumer } from '../contexts/UserContext';
import { useLocation } from 'react-router-dom';

function showMore(e, str, id) {
    e.preventDefault();
    document.getElementById("less-content-" + id).innerHTML = str;
    e.target.style.display = 'none';
}
function copyToClipboard(event){
    const e = document.getElementById(event.currentTarget.id);
    const closet = e.closest("div").querySelector(".pending-from");
    if(closet !== null){
        let txt = closet.innerHTML;
        navigator.clipboard.writeText(txt);
        e.closest("span").classList.add("tooltip");
        setTimeout(function(){ 
            e.closest("span").classList.remove("tooltip")
        }, 2000); 
    }
}
let icon, view_edit_url, hasRollbackPermission = false, myWorkListView = false, hasHomeTransactionPermission = false, wpsAdminArr = "";

export function SetTableActionPermission(props) {
    let { pathname } = useLocation();
    if (pathname === "/my-worklist") {
        myWorkListView = true;
    }
    else {
        myWorkListView = false;
    }
    wpsAdminArr = props.adminArr;
    
    return (
        <UserConsumer>
            {
                (userDetails) => {
                    if (Object.keys(userDetails).length !== 0) {
                        userDetails.permissions.forEach(el => {
                            if (el.resourceName === properties.resourceList.HOME_APP_VIEW_ICON) {
                                icon = <FaEye className="fa-icon2" title="View" />;
                                view_edit_url = properties.urlList.viewClientApplication;
                            } else if (el.resourceName === properties.resourceList.HOME_APP_EDIT_ICON) {
                                icon = <FaPen className="fa-icon2" title="Edit" />;
                                view_edit_url = properties.urlList.editClientApplication;
                            }
                            // if(el.resourceName === properties.resourceList.HOME_TRANSACTION_ICON) {
                            //     hasHomeTransactionPermission = true;
                            // }
                            if (el.resourceName === properties.resourceList.HOME_ROLLBACK_ICON) {
                                hasRollbackPermission = true;
                            }
                            if(myWorkListView && el.resourceName === properties.resourceList.PROMOTE_TO_PROD_ICON){
                                icon = <FaEye className="fa-icon2" title="View" />;
                                view_edit_url = properties.urlList.promoteClientApplication;
                            }
                        });
                        if (myWorkListView && props.activeTab === 0) {
                            icon = <FaEye className="fa-icon2" title="View" />
                            view_edit_url = properties.urlList.approveDeclineClientApplication;
                        }
                    }
                }
            }
        </UserConsumer>
    )
}

export const COLUMNS = [
    {
        Header: "ID",
        accessor: "clientAppId",
        Filter: ColumnFilter
    },
    {
        Header: "Name",
        accessor: "name",
        Filter: ColumnFilter,
        Cell: ({ cell }) => (
            <div className="more-less-content">
                {(cell.row.values.name.length > 50) ?
                    <><span className="less-content" id={"less-content-" + cell.row.values.clientAppId}>{cell.row.values.name.substring(0, 50)}</span><a href="!#" onClick={(e) => { showMore(e, cell.row.values.name, cell.row.values.clientAppId); }}>...</a></>
                    :
                    <p>{cell.row.values.name}</p>
                }
            </div>
        )
    },
    {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ cell: { value } }) => (
            <span className={"badge status-" + value.toLowerCase()}>
                {properties.statusList[value]}
            </span>
        )
    },
    {
        Header: "Managed By",
        accessor: "managedBy",
        Filter: ColumnFilter
    },
    {
        Header: "Email ID",
        accessor: "email",
        Filter: ColumnFilter
    },
    {
        Header: "Pending From",
        accessor: "pending_from",
        Filter: ColumnFilter,
        Cell: ({ row, cell }) => (
            <div className="colPendingFrom">
                <span className="pending-from">{wpsAdminArr}</span><span className="tooltip" data-tip={wpsAdminArr}><AiFillInfoCircle className="fa-icon" title="Info" /></span><span data-tip="Copied!"><AiFillCopy className="fa-icon" id={"copy-to-clip-"+row.values.clientAppId} title="Copy" onClick={copyToClipboard} /></span>
            </div>
        ),
        disableFilters: true
    },
    {
        Header: "Action",
        accessor: "action",
        Filter: ColumnFilter,
        Cell: ({ row, cell }) => (
            <div className="colAction">
                {view_edit_url && <HeaderLink url={{ "linkText": icon, "redirectURL": `${view_edit_url.replace(":appId", row.values.clientAppId)}` }} classList={""} />}
                {(hasHomeTransactionPermission && !myWorkListView) && <HeaderLink url={{ "linkText": "transaction", "redirectURL": properties.transactionSearchExternalLink, "external": true }} classList={""} />}
                {(!myWorkListView && hasRollbackPermission && cell.value.rollback) && <HeaderLink url={{ "linkText": properties.REVERT, "redirectURL": `${properties.urlList.rollbackClientApplication.replace(":appId", row.values.clientAppId)}` }} classList={""} />}
            </div>
        ),
        disableFilters: true
    }
];