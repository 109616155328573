import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import { BodyContainer } from './BodyContainer';
import { Footer } from './Footer';
import axios from 'axios';
import { ErrorFoundDialogbox } from './ErrorFoundDialogbox';
import { LoginRedirectDialogBox } from './LoginRedirectDialogBox';

export function MainContainer() {
    var redirectLogin = localStorage.getItem("redirectLogin");
    const [showError, setShowError] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
        setShowError(false);
        if(err.response.status === 401 || err.response.data.message === "401 Unauthorised"){
            setShowLogin(true);
        }
        else if(err.response.status === 500){
            setShowError(true);
        }
        return Promise.reject(err);
    })

    useEffect(() => {
        if(showLogin){
            var modal1 = document.getElementById("loginModal");
            modal1.style.display = "block";
        }
    },[showLogin])

    useEffect(() => {
        if(showError){
            var modal1 = document.getElementById("errorModal");
            modal1.style.display = "block";
        }
    },[showError])

    return(
        <>
            {showLogin ? <LoginRedirectDialogBox /> :
            <>
            <ErrorFoundDialogbox />
            <Header />
            <BodyContainer />
            <Footer />
            </>
            }
        </>
    );
}