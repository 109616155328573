import React from 'react';
import './App.css';
import { UseAccessToken } from './components/UseAccessToken';

export default function App() {
  return (
    <>
      <UseAccessToken />
    </>
  );
}