import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import { MainContainer } from './MainContainer';
import { AppTokenProvider } from './../contexts/AppTokenContext';
import axios from 'axios';
import { UserProvider } from "../contexts/UserContext";
import { env } from "../env";

export function UseAccessToken() {

    const baseURL = env.REACT_APP_BASE_URL;
    
    const { instance, accounts } = useMsal();
    const [accessTokenSsoObj, setAccessTokenSsoObj] = useState({});
    const [userDetails, setUserDetails] = useState({});

    const getUserAccessToken = () => {
        if (accounts.length > 0) {
            let ssoId = accounts[0].username.split("@")[0];
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                setAccessTokenSsoObj({
                    ssoId: ssoId,
                    accessToken: response.accessToken
                });
            }).catch(error => {
                // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                console.log(error);
            });
        }
    }

    const getUserDetails = async () =>{
        try{
            const userDetailsRes = await axios.get(baseURL + "/v1/users/" + accessTokenSsoObj.ssoId,
            {
                headers: {
                "Authorization": 'Bearer ' + accessTokenSsoObj.accessToken
                }
            })
            setUserDetails(userDetailsRes.data);
        }
        catch(e){
            if(e.response !== undefined){
                document.getElementById("errorModal").getElementsByTagName('p')[0].innerHTML = e.response.data.errorMessage.toLowerCase();
                var modal1 = document.getElementById("errorModal");
                modal1.style.display = "block";
            }
        }
    }

    useEffect(()=>{
        getUserAccessToken();
    },[]);

    useEffect(()=>{
        if(accessTokenSsoObj.ssoId != null) {
            getUserDetails();
        }
    },[accessTokenSsoObj.accessToken]);

    return (
        <AppTokenProvider value={accessTokenSsoObj.accessToken}>
            <UserProvider value={userDetails}>
                <MainContainer/>
            </UserProvider>
        </AppTokenProvider>
    )
}
