import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./config/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LoginRedirect } from './components/LoginRedirect';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
         <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                  <LoginRedirect />
            </UnauthenticatedTemplate>
         </MsalProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
